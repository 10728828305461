import { ReactNode } from "react";

import { defaultTheme } from "@4pace-cpq/core";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Theme, ThemeProvider, createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { theme as VBTheme } from "./theme/theme";

const cacheNoPrefixer = createCache({
  //removes the vendor prefix on dev
  key: "noprefixer",
  ...(process.env.NODE_ENV === "development" && { stylisPlugins: [] }),
});
const theme = createTheme(deepmerge(defaultTheme as Theme, VBTheme));
const queryClient = new QueryClient();

export function Providers({ children }: { children: ReactNode }): JSX.Element {
  return (
    <CacheProvider value={cacheNoPrefixer}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
