import { Box } from "@mui/material";

import { AppHeaderNavItem } from "./AppHeaderNavItem";

export interface INavItem {
  label: string;
  href: string;
  subItems: string[];
  isDummy?: boolean;
}

export function AppHeaderNav(): JSX.Element {
  const navDataArr: INavItem[] = [
    /*    {
      label: "Über Uns",
      href: "#",
      subItems: ["x"],
    },
    {
      label: "Produkte",
      href: "#",
      subItems: ["x"],
    },
    {
      label: "Lösungen",
      href: "#",
      subItems: ["x"],
    },
    {
      label: "Investoren",
      href: "#",
      subItems: [],
    },
    {
      label: "Ethik & Compliance",
      href: "#",
      subItems: ["x"],
    },
    {
      label: "Support",
      href: "#",
      subItems: ["x"],
    }, */
    {
      label: "Home",
      href: "/",
      subItems: [],
    },
    {
      label: "Productfinder",
      href: "/configurator",
      subItems: [],
    },
  ];

  const navItemElements = navDataArr.map((navItem, index) => {
    return (
      <AppHeaderNavItem
        navItem={navItem}
        key={navItem.label + index}
        index={index}
        active={navItem.label === "Productfinder"}
      />
    );
  });

  return (
    <Box display="flex" alignItems="center">
      {navItemElements}
    </Box>
  );
}
