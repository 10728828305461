export default {
  WEG: {
    "buttons.backToCart": "Zur Produktübersicht",
    "buttons.backToRoot": "Zur Startseite",
    "buttons.addToQuote": "Zur Artikelliste hinzufügen",
    "buttons.saveAsTemplate": "Vorlage speichern",
    "buttons.resetConfiguration": "Reset",
    "products.buttons.configure": "Konfigurieren",
    "products.buttons.addToQuote": "Zur Artikelliste hinzufügen",
    "latestProjects.table.title": "Aktuellste Projekte",
    "latestProjects.table.header.projectNumber": "Projektnummer",
    "latestProjects.table.header.projectName": "Projektname",
    "latestProjects.table.header.batch": "Kommission",
    "latestProjects.table.header.orderNumber": "Bestellnummer",
    "latestProjects.table.header.lastChange": "Letzte Änderung",
    "buttons.showAllProjects": "Alle Projekte anzeigen",
  },
  CPQ: {
    "button.createQuote": "test", ////todo -[ am ]-  fix
  },
};
