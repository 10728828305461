import { appLanguage } from "../App";
import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import { i18n as i18n_CpqCore } from "@4pace-cpq/core";

// import { initReactI18next } from "react-i18next";
import de_DE from "./de_DE";
import en_US from "./en_US";

//noinspection JSIgnoredPromiseFromCall
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ["translation"], //important to overwrite the default ns of cpq
    defaultNS: "translation",
    debug: true,
    lng: appLanguage ? appLanguage : "en_US",
    fallbackLng: "cimode",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      "de-DE": de_DE,
      "en-US": en_US,
    },
  });

console.log("i18n standard, not nested", { standard: i18n });

i18n_CpqCore.addResourceBundle("de-DE", "WEG", de_DE.WEG);
i18n_CpqCore.addResourceBundle("en-US", "WEG", en_US.WEG);

export default i18n;
