import { CpqCore, OverridableComponentsKeys } from "@4pace-cpq/core";

//import { CustomIndexRouteComponent } from "./CustomIndexRouteComponent";
import { Providers } from "./Providers";
import { AppHeader } from "./components/AppHeader/AppHeader";
import { ConfiguratorContainer } from "./configurator/ConfiguratiorContainer";

/* import { prettyLogger } from "./prettyLogger";
import { customPalette } from "./theme/theme"; */

/* prettyLogger(
  `FE Version ${__CPQ_CORE_FE_VERSION__}`,
  customPalette.primary.main,
); */

export const appLanguage = "en-US";

export function App(): JSX.Element {
  return (
    <Providers>
      <CpqCore
        languageCode={appLanguage}
        showDebugTools
        componentsOverride={{
          /* [OverridableComponentsKeys.IndexRouteComponent]: () => (
            <CustomIndexRouteComponent />
          ), */
          [OverridableComponentsKeys.QuoteDetailsViewPageHeader]: () => <></>,
          [OverridableComponentsKeys.MainHeader]: AppHeader,
          [OverridableComponentsKeys.GoBackToOpportunitiesButton]: () => <></>,
          [OverridableComponentsKeys.ConfiguratorRouteComponent]: () => (
            <ConfiguratorContainer />
          ),
        }}
      />
    </Providers>
  );
}
