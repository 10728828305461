export default {
  WEG: {
    "buttons.backToCart": "To product overview",
    "buttons.backToRoot": "To the homepage",
    "buttons.addToQuote": "Add to item list",
    "buttons.saveAsTemplate": "Save template",
    "buttons.resetConfiguration": "Reset",
    "products.buttons.configure": "Configure",
    "products.buttons.addToQuote": "Add to item list",
    "latestProjects.table.title": "Most recent projects",
    "latestProjects.table.header.projectNumber": "Project number",
    "latestProjects.table.header.projectName": "Project name",
    "latestProjects.table.header.batch": "Commission",
    "latestProjects.table.header.orderNumber": "Order number",
    "latestProjects.table.header.lastChange": "Last change",
    "buttons.showAllProjects": "Show all projects",
    "button.upload": "Upload",
  },
  CPQ: {
    "button.createQuote": "test", ////todo -[ am ]-  fix
  },
};
