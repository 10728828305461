import { useState } from "react";

import { Button, CircularProgress, Link } from "@mui/material";

export const JumpToEasyBtn = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleClick = async (): Promise<boolean> => {
    setIsLoading(true);

    /* 
    let response;
    try {
      response = await fetch("/api/Easy/JumpToEasy", {
        method: "GET",
      });
      console.log("response", response);
      const data = await response.json();
      console.log("data", data);
      if (typeof data?.url === "string") {
        window.location.assign(data.url);
      } 
    } catch (error) {
      console.warn(error);
    }*/

    //setIsLoading(false);

    return true;
  };

  return (
    <>
      <a href="/api/Easy/RedirectToEasy">test</a>
      <Button
        component={Link}
        href="/api/Easy/RedirectToEasy"
        variant="outlined"
        size="small"
        onClick={handleClick}
      >
        {isLoading ? (
          <CircularProgress size={"1em"} sx={{ mr: ".25em" }} />
        ) : null}
        Jump to Easy
      </Button>
    </>
  );
};
