import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";

export const customPalette = {
  mode: "light",
  primary: {
    main: "#0091BD",
    dark: "#3572A6",
    contrastText: "#fff",
  },
  secondary: {
    main: "rgba(0, 0, 0, 0.6)",
  },
  grey: {
    100: "#F5F5F5",
    300: "#D9DADA",
    500: "#767676",
    600: "#646464",
    800: "#44474A",
    900: "#333333",
  },
} satisfies PaletteOptions;

const customTheme = createTheme({
  palette: customPalette,
  colorSchemes: {
    dark: false,
  },
  typography: {},
  shape: {},
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      //TODO cleanup, very hacky in here
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 0,
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            boxShadow: "0 0 0 1px #BDBFC4 inset",
            pointerEvents: "none",
          },
          "&:hover": {
            backgroundColor: "#fff",
            "&::before": {
              boxShadow: "0 0 0 3px " + customPalette.primary.main + " inset",
            },
          },

          ".MuiButtonBase-root > .MuiBox-root:last-of-type": {
            background: customPalette.primary.main,
            color: "#fff",
            ".MuiCardContent-root": {
              padding: ".6rem .6rem",
            },
          },
          "&.MuiPaper-elevation8": {
            "&::before": {
              boxShadow: "0 0 0 3px " + customPalette.primary.dark + " inset",
            },
            ".MuiButtonBase-root > .MuiBox-root:last-of-type": {
              background: customPalette.primary.dark,
            },
          },
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          objectFit: "cover",
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(customTheme);
